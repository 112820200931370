import { getDataMapper, mapArray, mapField } from "@/shared";

export const mapRepairStatusFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    const: mapField("const"),
    color: mapField("color"),
    isSystemStatus: mapField("is_system_status", Boolean),
});

export const mapRepairStatusesFromServer = mapArray(mapRepairStatusFromServer);
