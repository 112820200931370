<script setup>
import Chatra from "@chatra/chatra";
import { storeToRefs } from "pinia";
import { computed, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
    useIntegrationsStore,
    useEducationStore,
    useSettingsStore,
    detectKkmServer,
    loadWebRTC,
} from "@/entities";
import { TheRouting, isAuthRoute } from "@/pages";
import { useAuth, initWorkspace, setResponseInterceptor } from "@/processes";
import {
    BaseLoader,
    BaseNotifications,
    setScrollBarToCssVariables,
    GENERAL_ROUTE_NAMES,
} from "@/shared";
import {
    TheSidebar,
    TheHeader,
    useSidebarStore,
    Education,
    IntegrationIntegrillaChat,
} from "@/widgets";

// TODO: исправить eslint так, чтобы это не была ошибка
// eslint-disable-next-line boundaries/element-types
import { useDynamicTitle } from "../lib/useDynamicTitle";

// interceptors
setResponseInterceptor();

// title with route meta
useDynamicTitle();

// auth
const router = useRouter();
const route = useRoute();

// Education
const educationStore = useEducationStore();
const { isShowEducation, shouldShowEducation } = storeToRefs(educationStore);
const { settings } = storeToRefs(useSettingsStore());

const onAuthenticate = async () => {
    await initWorkspace();
    const isCurrentRouteAuth = isAuthRoute(route.name);

    if (isCurrentRouteAuth)
        await router.push({ name: GENERAL_ROUTE_NAMES.DEFAULT });

    if (shouldShowEducation.value && settings.value.firstEnter) {
        educationStore.showEducation();
        shouldShowEducation.value = false;
    }
};

const { isAuthorized, isAuthenticated } = useAuth({
    onAuthenticate,
});

// already authenticated but not yet authorized
const isAuthorizing = computed(
    () => isAuthenticated.value && !isAuthorized.value
);

// sidebar controlling
const { toggleCollapsedMode, setCollapsedMode } = useSidebarStore();
const { isCollapsed } = storeToRefs(useSidebarStore());

// set scrollbar width
setScrollBarToCssVariables("--default-scrollbar-width");

// check and run integrations
const integrationsStore = useIntegrationsStore();
const { zadarma, integrilla } = storeToRefs(integrationsStore);
const integrillaChat = ref(null);

watch(isAuthorizing, () => {
    if (!isAuthorizing.value) {
        integrationsStore.setAtolIntegrated(detectKkmServer());

        if (zadarma.value.atc && zadarma.value.webRTCKey) {
            loadWebRTC(zadarma.value.webRTCKey, zadarma.value.atc);
        }
    }
});

// Тупо - надо сделать через проверку переменных окружения, возможно хорнить в LocalSotrage вкл/выкл чатра и какой ключ
const initChatra = function () {
    let config = {
        ID: "95GTL2AqNvABsQ59X",
    };

    window.Chatra = Chatra;
    window.Chatra("init", config);
};

initChatra();
// translations
// useTranslations();
</script>

<template>
    <template v-if="isAuthorized">
        <TheHeader
            @change-sidebar-mode="toggleCollapsedMode"
            @toggle-chat="
                integrillaChat.isChatOpen
                    ? integrillaChat.hide()
                    : integrillaChat.show()
            "
            :is-sidebar-collapsed="isCollapsed"
        />
        <TheSidebar />
        <main class="app__authorized">
            <TheRouting />
        </main>
        <IntegrationIntegrillaChat
            ref="integrillaChat"
            v-if="integrilla.chat && integrilla.token"
        />
        <Education
            v-if="isShowEducation"
            @change-sidebar-mode="setCollapsedMode"
        />
    </template>
    <template v-else>
        <main class="app__unauthorized">
            <BaseLoader v-if="isAuthorizing" />
            <TheRouting v-else />
        </main>
    </template>
    <BaseNotifications
        position="top right"
        :x-offset="12"
        :y-offset="75"
        :default-duration="4500"
        ignore-duplicates
    />
</template>

<style scoped lang="scss">
.app {
    &__authorized {
        // width: calc(100vw - var(--sidebar-width));
        padding: var(--main-paddings);
        margin-top: var(--header-height);
        margin-left: var(--sidebar-width);

        transition: all var(--sidebar-collapse-transition-duration);

        @media (max-width: 576px) {
            width: 100vw;
            margin-left: 0;
        }
    }

    &__unauthorized {
        width: 100%;
        height: 100vh;
        overflow: hidden;

        @media (max-width: 576px) {
            height: unset;
            min-height: 100vh;
            overflow-y: auto;

            background-color: var(--c-neutral-10);
        }
    }
}
</style>
