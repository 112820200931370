import { getDataMapper, mapField, isPrimitive } from "@/shared";

export const mapIntegrillaStatusFromServer = getDataMapper({
    notifications: mapField("notifications", (item) => item === "enabled"),
    chat: mapField("chat", (item) => item === "enabled"),
    token: mapField("api_key"),
});

export const mapIntegrationsStatusesFromServer = getDataMapper({
    amoCRM: mapField("amo", {
        isIntegrated: mapField("status", (item) => item === "enabled"),
    }),
    googleCalendar: mapField("google_calendar", {
        isIntegrated: mapField("status", (item) => item === "enabled"),
        calendarId: mapField("calendar_id"),
    }),
    myCalls: mapField("my_calls", {
        calls: mapField(
            "status",
            (item) => {
                if (isPrimitive(item)) return false;

                if (item.length < 2) return false;

                if (Number(item[1]) === 3) return true;

                if (Number(item[1]) === 1) return true;

                return false;
            },
            { applyToElements: false }
        ),
        sms: mapField(
            "status",
            (item) => {
                if (isPrimitive(item)) return false;

                if (item.length < 2) return false;

                if (Number(item[1]) === 3) return true;

                if (Number(item[1]) === 2) return true;

                return false;
            },
            { applyToElements: false }
        ),
    }),
    smsDiscount: mapField("sms_discount", {
        isIntegrated: mapField("status", (item) => item === "enabled"),
        activeName: mapField("active_name"),
    }),
    zadarma: mapField("zadarma", {
        isIntegrated: mapField("status", (item) => item === "enabled"),
        atc: mapField("atc"),
        webRTCKey: mapField("webRTCKey"),
    }),
    fDoc: mapField("fdoc", {
        companyStatus: mapField("company_status", (item) => item === "enabled"),
        userStatus: mapField("user_status", (item) => item === "enabled"),
    }),
    integrilla: mapField("integrilla", mapIntegrillaStatusFromServer),
});
