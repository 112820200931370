<script setup>
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";

import { useIntegrationsStore } from "@/entities";

const { integrilla } = storeToRefs(useIntegrationsStore());

const chat = ref(null);
const isChatOpen = ref(false);
const chatButton = ref(document.getElementById("integrilla-button"));

const show = () => {
    isChatOpen.value = true;
};

const hide = () => {
    isChatOpen.value = false;
};

onMounted(() => {
    document.addEventListener("click", (e) => {
        if (
            chat.value &&
            chatButton.value &&
            e.target !== chat.value &&
            e.target !== chatButton.value
        )
            hide();
    });
});

onUnmounted(() => {
    document.removeEventListener("click");
});

defineExpose({ isChatOpen, show, hide });
</script>

<template>
    <Transition name="slide">
        <div v-show="isChatOpen" class="integration-integrilla-chat" ref="chat">
            <iframe
                name="PartnerChat"
                :src="`https://chat.touch-api.com/chat?widget-theme=light&mode=widget&widget-language=ru_RU&crm=rentinhand&token=${integrilla.token}`"
                allow="microphone *; clipboard-write *"
                frameborder="0"
                height="100%"
                width="100%"
            />
        </div>
    </Transition>
</template>

<style lang="scss" scoped>
.integration-integrilla-chat {
    position: fixed;
    top: var(--header-height);
    right: 0;
    z-index: var(--zi-modal);

    width: 600px;
    height: calc(100vh - var(--header-height));

    background-color: var(--c-foreground);

    box-shadow: -2px 2px 5px 2px rgba(0 0 0 / 8%);

    @media (max-width: 767px) {
        width: 100%;
    }

    &__header {
        display: flex;

        align-items: center;

        justify-content: space-between;

        padding: 10px;
        margin-bottom: 10px;
    }

    &__heading {
        font-size: 16px;
        font-weight: 700;
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease-out;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(600px);
}
</style>
