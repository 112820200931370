import {
    getDataMapper,
    mapField,
    parseISO,
    mapArray,
    mapHumanFromServer,
    mapPointFromServer,
} from "@/shared";

import { mapClientFromServer } from "@/entities/client/@x";
import { mapProductFromServer } from "@/entities/products/@x";

export const mapStoreSellingFromServer = getDataMapper({
    id: mapField("id", Number),
    number: mapField("order_number", Number),
    timeStart: mapField("created_at", parseISO),
    client: mapField("client", mapClientFromServer),
    clientId: mapField("human", (client) => client?.id),
    adminUser: mapField("admin_user", mapHumanFromServer),
    products: mapField("products", {
        id: mapField("id", Number),
        product: mapField("product", mapProductFromServer),
    }),
    sum: mapField("sum_real", Number),
    paid: mapField("payed", Number),
    point: mapField("point", mapPointFromServer),
    fDocDocuments: mapField("fdoc_documents", {
        id: mapField("id"),
        link: mapField("reference"),
        status: mapField("status"),
        title: mapField("name"),
    }),
});

export const mapStoreSellingsFromServer = mapArray(mapStoreSellingFromServer);
